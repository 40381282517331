.dai-advisors-submenu {
  display: table;   /* Allow the centering to work */
  margin: 0 auto;
  width: 100%;
  text-align: center;
  background-color: white;
  max-height: 10%;
}

ul.dai-advisors-submenu-list {
  list-style-type: none;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 0px;
  overflow: hidden;
  text-align: center;
}

ul.dai-advisors-submenu-list li {
  display: inline-block;
  color: grey;
  float: none;
}

ul.dai-advisors-submenu-list li a {  
  color: grey;
  text-align: center;
  display: block;
  padding: 30px 10px 30px;
  text-decoration: none;
}

ul.dai-advisors-submenu-list li a:hover {  
  color: #0071C1;
}

ul.dai-advisors-submenu-list li .active {
  color: #0071C1;
}

@media only screen and (max-width: 960px) {
  ul#about-menu-list li a {  
    font-size: 14px;
  }
}

@media only screen and (min-width: 961px) {
  ul#about-menu-list li a {  
    font-size: 18px;
  }
}