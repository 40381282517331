ul#dai-advisors-nav-list li {
  float: right;
}

ul#dai-advisors-nav-list {
  list-style-type: none;
  margin-top: 0px;
  margin-bottom: 0px;
  background-color: white;
  padding-inline-end: 2%;
  padding-inline-start: 2%;
  overflow: hidden;
}

ul#dai-advisors-nav-list li a {  
  display: block;
  color: grey;
  text-align: center;
  text-decoration: none;
}

@media only screen and (max-width: 960px) {
  ul#dai-advisors-nav-list li a {  
    display: block;
    color: grey;
    text-align: center;
    text-decoration: none;
    padding: 30px 2px;
  }
}

@media only screen and (min-width: 961px) {
  ul#dai-advisors-nav-list li a {  
    display: block;
    color: grey;
    text-align: center;
    padding: 30px 16px;
  }
}

ul#dai-advisors-nav-list li img {
  display: block;
  height: auto; 
  width: auto; 
  padding: 10px 0px;
  max-width: 100px; 
  max-height: 100px;
}

ul#dai-advisors-nav-list li a:hover {
  color: #0071C1;
}

ul#dai-advisors-nav-list #nav-bar-logo {
  float: left;
}

ul#dai-advisors-nav-list #nav-bar-logo-link {
  padding: 0px 0px;
}


ul#dai-advisors-nav-list li .active {  
  color: #0071C1;
}

#dai-advisors-nav-bar {
  top: 0;
  z-index: 100;
  position: fixed;
  width: 100%;
}
