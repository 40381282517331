#contact-title {
  text-align: center;
  width: 100%;
}

#dai-advisors-contact {
  padding-top: 10vh;
}

#contact-splash-img {
  background-image: url('./contact-splash.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.contact-form-container {
  padding: 5% 10%;
  overflow: auto;
}

#contact-email {
  font-size: 16px;
}

.contact-form-input {
  margin-bottom: 30px;
}

.contact-form-label {
  margin-bottom: 0px;
}

#contact-form-info {
  margin-bottom: 30px;
}

#contact-send-button {
  background-color: #0071C1;
  color: white;
  text-decoration: none;
  border: none;
  display: inline-block;
  padding: 2% 8%;
  border-radius: 4px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 960px) {
  .contact-form {
    width: 100%;
  }

  #contact-title {
    font-size: 48px;
  }
  
  #contact-right-side {
    width: 100%;
  }
}

@media only screen and (min-width: 961px) {
  .contact-form {
    float: left;
    width: 60%;
  }

  #contact-title {
    font-size: 64px;
  }
  #contact-right-side {
    float: left;
    width: 30%;
    margin-left: 5%;
  }
}