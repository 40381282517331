#dai-advisors-footer {
  display: table;   /* Allow the centering to work */
  margin: 0 auto;
  width: 100%;
  text-align: center;
  background-color: white;
  max-height: 25%;
}

ul#dai-advisors-footer-list {
  list-style-type: none;
  padding-inline-end: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  overflow: hidden;
  text-align: center;
}

ul#dai-advisors-footer-list li {
  display: inline-block;
  color: grey;
  float: none;
  font-size: 12px;
}

ul#dai-advisors-footer-list li a {  
  color: grey;
  text-align: center;
  font-size: 12px;
  display: block;
  padding: 30px 10px 20px;
  text-decoration: none;
}

ul#dai-advisors-footer-list li a:hover {
  color: #0071C1;
}

#copyright {
  font-size: 12px;
  padding: 0px 0px 10px;
  color: grey;
}