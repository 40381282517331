#services-investment-advisory {
  padding-top: 5%;
  padding-bottom: 5%;
  width: 80%;
  max-height: 100%;
  overflow: auto;
  margin-left: 10%;
  margin-right: 10%;
}

#services-title {
  padding: 10% 20%;
}

#dai-advisors-services {
  padding-top: 10vh;
}

#services-splash-img {
  background-image: url('./services-splash.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#investment-advisory-text {
  text-align: center;
  margin-left: 15%;
  margin-right: 15%;
}

#investment-advisory-row {
  padding-bottom: 10%;
}