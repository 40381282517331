html {
  height: 100%;
}

body {
  height: 100vh;
  font-family: "Raleway",sans-serif;
}

@viewport {
  width: device-width;
  zoom: 1.0;
}

.dai-advisors-page {
  background-color: #f5f5f5;
}

#dai-advisors-container {
  height: 100%;
  width: 100%;
}

.splash-img {
  color: white;
  opacity: 0.8;
}

@media only screen and (max-width: 960px) {
  .splash-img {
    height: 40vh;
    width: 100%;
  }

  .splash-img-title {
    padding: 10% 20%;
    font-size: 48px;
  }
}

@media only screen and (min-width: 961px) {
  .splash-img {
    height: 50vh;
    width: 100%;
  }

  .splash-img-title {
    padding: 10% 20%;
    font-size: 64px;
  }
}