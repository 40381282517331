#home-splash-img {
  max-height: 100vh;
  height: auto;
  text-align: center;
  color: white; 
}

#dai-advisors-home {
  margin-top: 10vh;
}

#home-splash {
  background-image: url('./city-splash.jpg');
  color: white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  opacity: 0.9;
}

.home-splash-title {
  padding: 15% 5%;
}

.home-investment-advisory {
  color: black;
}

#home-investment-advisory-text {
  padding: 10% 15%;
  margin: 0;
}

#home-contact-link {
  text-decoration: none;
}

@media only screen and (min-width: 961px) {
  #home-splash {
    font-size: 64px;
  }
}

@media only screen and (max-width: 961px) {
  #home-splash {
    font-size: 36px;
  }
}