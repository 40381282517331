#dai-advisors-about {
  padding-top: 10vh;
  padding-bottom: 10vh;
}

#about-splash-img {
  background-image: url('./financial-splash.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.dai-advisors-title {
  margin-top: 12%;
  height: 20%;
}

.dai-advisors-about-section {
  margin-top: 5%;
  width: 100%;
  max-height: 100%;
  height: 30%;
  overflow: auto;
}

.dai-advisors-bio-img {
  margin-top: 5%;
}

#dai-advisors-headshot {
  height: 50%;
  width: 50%;
}

@media only screen and (max-width: 960px) {
  .dai-advisors-bio-img {
    margin-left: 10%;
    margin-right: 10%;
    width: 100%;
  }
  #dai-advisors-headshot {
    width: 50%;
  }
  #bio-name {
    font-size: 26px;
  }
  .dai-advisors-bio-text {
    margin-left: 5%;
    margin-right: 10%;
    max-width: 100%;
    width: 80%;
  }
  #bio-title {
    font-size: 16px;
  }
}

@media only screen and (min-width: 961px) {
  .dai-advisors-bio-text {
    width: 25%;
    float: left;
    margin-left: 5%;
    margin-right: 20%;
  }
  .dai-advisors-bio-img {
    float: left;
    width: 25%;
    margin-left: 20%;
    margin-right: 5%;
  }
  #dai-advisors-headshot {
    width: 100%;
  }
  #bio-title {
    font-size: 22px;
  }
}